require('./bootstrap');



// resources/assets/js/app.js
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/sortable.js';
//add as many widget as you may need


$('.sortable').sortable();